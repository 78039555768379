// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akcio-js": () => import("./../../../src/pages/akcio.js" /* webpackChunkName: "component---src-pages-akcio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joginyilatkozat-js": () => import("./../../../src/pages/joginyilatkozat.js" /* webpackChunkName: "component---src-pages-joginyilatkozat-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */),
  "component---src-pages-szolgaltatasaink-foto-js": () => import("./../../../src/pages/szolgaltatasaink/foto.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-foto-js" */),
  "component---src-pages-szolgaltatasaink-js": () => import("./../../../src/pages/szolgaltatasaink.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-js" */),
  "component---src-pages-szolgaltatasaink-kontaktlencsevizsgalat-js": () => import("./../../../src/pages/szolgaltatasaink/kontaktlencsevizsgalat.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-kontaktlencsevizsgalat-js" */),
  "component---src-pages-szolgaltatasaink-latasvizsgalat-js": () => import("./../../../src/pages/szolgaltatasaink/latasvizsgalat.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-latasvizsgalat-js" */),
  "component---src-pages-szolgaltatasaink-szemuveg-js": () => import("./../../../src/pages/szolgaltatasaink/szemuveg.js" /* webpackChunkName: "component---src-pages-szolgaltatasaink-szemuveg-js" */),
  "component---src-pages-termekeink-gyerekeknek-js": () => import("./../../../src/pages/termekeink/gyerekeknek.js" /* webpackChunkName: "component---src-pages-termekeink-gyerekeknek-js" */),
  "component---src-pages-termekeink-js": () => import("./../../../src/pages/termekeink.js" /* webpackChunkName: "component---src-pages-termekeink-js" */),
  "component---src-pages-termekeink-kiegeszitok-js": () => import("./../../../src/pages/termekeink/kiegeszitok.js" /* webpackChunkName: "component---src-pages-termekeink-kiegeszitok-js" */),
  "component---src-pages-termekeink-kontaktlencsek-js": () => import("./../../../src/pages/termekeink/kontaktlencsek.js" /* webpackChunkName: "component---src-pages-termekeink-kontaktlencsek-js" */),
  "component---src-pages-termekeink-napszemuvegek-js": () => import("./../../../src/pages/termekeink/napszemuvegek.js" /* webpackChunkName: "component---src-pages-termekeink-napszemuvegek-js" */),
  "component---src-pages-termekeink-szemuvegkeretek-js": () => import("./../../../src/pages/termekeink/szemuvegkeretek.js" /* webpackChunkName: "component---src-pages-termekeink-szemuvegkeretek-js" */),
  "component---src-pages-termekeink-szemuveglencsek-js": () => import("./../../../src/pages/termekeink/szemuveglencsek.js" /* webpackChunkName: "component---src-pages-termekeink-szemuveglencsek-js" */)
}

